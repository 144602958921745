<template>
  <div class="container">
    <div class="content">
      <div class="item">
        <div class="left">{{ community.amount }}：</div>
        <div class="right">1000万MEB</div>
      </div>
      <div class="item">
        <div class="left">{{ community.purpose }}：</div>
        <div class="right">{{community.purposeDetails}}</div>
      </div>
      <div class="item">
        <div class="left">{{ community.number }}：</div>
        <div class="right">100000 MEB ,{{community.numberTips}}</div>
      </div>
      <div class="item">
        <div class="left">{{ community.givingRights }}：</div>
        <div class="right">{{community.equityContent}}</div>
      </div>
      <div class="item">
        <div class="left">{{ community.donations }}：</div>
        <div class="right">{{community.alreadyExisting}}22{{community.situationContent}}，{{community.surplus}}78{{community.seat}}</div>
      </div>
      <div class="item">
        <div class="left">{{ community.numberOfDonations }}：</div>
        <div class="right">32412364MEB</div>
      </div>
      <div class="item">
        <div class="left">{{ community.address }}：</div>
        <div class="right">{{ address }}</div>
      </div>
      <div class="copy clipboardBtn" :data-clipboard-text="address" @click="handleCopy">{{community.copy}}</div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  name: 'Community',
  data () {
    return {
      address: '0x2U5G21UYG5U2G1J35GJ2135GHJ5G213G5K'
    }
  },
  methods: {
    // 复制地址
    handleCopy () {
      const clipboard = new Clipboard('.clipboardBtn')
      clipboard.on('success', e => {
        this.$toast('Success')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    }
  },
  computed: {
    community () {
      return this.$t('community')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 24px;
  background: #242424;
  min-height: 100vh;

  .content {
    color: #fff;
    padding: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;

    .item {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;
      line-height: 20px;

      .left {
        margin-bottom: 8px;
        color: rgba(255, 255, 255, 0.6);
      }
      .right {
        word-break: break-all;
      }
    }
    .copy {
        width: 100%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #6667FF;
        border-radius: 12px;
      }
  }
}
</style>
